import { Dialog } from '@mui/material'
import React from 'react'
import { IKeyCode } from '../../redux/KeyCodes'
import TableKeyNotes from '../KeyNotes/TableKeyNotes'
import FormKeyCodes from './FormKeyCodes'

interface IKeyAdminDialog {
  openDialog: boolean
  handleClose: () => void
  handlerOnSubmit: React.FormEventHandler<HTMLFormElement>
  setInputs: React.Dispatch<React.SetStateAction<IKeyCode | null>>
  inputs: IKeyCode | null
}

export default function KeyAdminDialog(props: IKeyAdminDialog) {
  const { openDialog, handleClose, handlerOnSubmit, setInputs, inputs } = props
  const [open, setOpen] = React.useState(false)

  if (inputs === null) return null
  return (
    <Dialog open={openDialog} onClose={handleClose} fullWidth>
      <FormKeyCodes
        inputs={inputs}
        setInputs={setInputs}
        handlerOnSubmit={handlerOnSubmit}
        open={open}
        setOpen={setOpen}
        handleClose={handleClose}
      />
      <TableKeyNotes KeyId={inputs.KeyId} />
    </Dialog>
  )
}
