import axios from 'axios'
import React from 'react'
import { BACKEND_URL, xApiKey } from '../constants'

async function getKeyNotes(KeyId: number) {
  return await axios.get(`${BACKEND_URL}/key-notes`, {
    params: { KeyId },
    headers: {
      'x-api-key': xApiKey
    }
  })
}

export interface IKeyNotes {
  Date: string
  Id: number
  IsTrackingNote: boolean
  KeyId: number
  Note: string
  Username: string
}

export default function useKeyNotes(KeyId?: number) {
  const [keyNotes, setKeyNotes] = React.useState<IKeyNotes[]>([])

  React.useEffect(() => {
    if (KeyId) {
      // fetch notes
      getKeyNotes(KeyId).then((result) => {
        setKeyNotes(result.data.map((note: IKeyNotes) => ({ id: note.Id, ...note })))
      })
    }
  }, [KeyId])

  return { keyNotes, setKeyNotes }
}
