import { GridToolbarContainer, GridRowsProp } from '@mui/x-data-grid'
import AddIcon from '@mui/icons-material/Add'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, TextField } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store'
import { useState } from 'react'
import { toogleLoadingBackdrop } from '../../redux/Settings'
import { BACKEND_URL, xApiKey } from '../../constants'
import axios from 'axios'

interface AddNotesToolbarProps {
  setKeyNotes: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void
  KeyId: number
}

export default function AddNotesToolbar({ KeyId, setKeyNotes }: AddNotesToolbarProps) {
  const dispatch = useDispatch()
  const userName = useSelector((state: RootState) => state.authenticationReducer.rootStore.userStore.username)
  const [open, setOpen] = useState(false)

  const handleCreateNoteManually = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const formData = new FormData(event.currentTarget)
    const formJson = Object.fromEntries((formData as any).entries())
    const note = [formJson.note]
    dispatch(toogleLoadingBackdrop())
    try {
      const response = await axios.post(
        `${BACKEND_URL}/key-notes`,
        {
          note,
          KeyId,
          userName
        },
        {
          headers: {
            'x-api-key': xApiKey
          }
        }
      )
      setKeyNotes((oldRows) => [...oldRows, { id: response.data.Id, ...response.data }])
      setOpen(false)
    } catch (error) {
      console.error(error)
    }
    dispatch(toogleLoadingBackdrop())
  }

  return (
    <GridToolbarContainer>
      <Button color='primary' startIcon={<AddIcon />} onClick={() => setOpen(true)}>
        Add Note
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          component: 'form',
          onSubmit: handleCreateNoteManually
        }}
      >
        <DialogContent>
          <DialogContentText>You can leave your message on the next field</DialogContentText>
          <TextField
            autoFocus
            required
            margin='dense'
            id='note'
            name='note'
            label='Note'
            type='string'
            fullWidth
            variant='standard'
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button type='submit'>Save</Button>
        </DialogActions>
      </Dialog>
    </GridToolbarContainer>
  )
}
