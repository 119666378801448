import * as React from 'react'
import useKeyNotes from '../../customHooks/useKeyNotes'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import AddNotesToolbar from './AddNotesToolbar'

interface ITableKeyNotes {
  KeyId: number
}

const columns: GridColDef[] = [
  {
    field: 'Date',
    headerName: 'Date',
    type: 'dateTime',
    width: 180,
    valueFormatter(params: { value: string }) {
      return new Date(params.value as string).toLocaleString()
    }
  },
  {
    field: 'Username',
    headerName: 'User name',
    type: 'string',
    width: 140,
    align: 'left',
    headerAlign: 'left'
  },
  {
    field: 'Note',
    headerName: 'Note',
    type: 'string',
    width: 600
  }
]

export default function TableKeyNotes({ KeyId }: ITableKeyNotes) {
  const { keyNotes, setKeyNotes } = useKeyNotes(KeyId)
  return (
    <DataGrid
      rows={keyNotes}
      columns={columns}
      editMode='row'
      slots={{ toolbar: AddNotesToolbar }}
      slotProps={{ toolbar: { setKeyNotes, KeyId } }}
    />
  )
}
