import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import React from 'react'
import FieldKeyValue from './FieldKeyValue'
import { IKeyCode } from '../../redux/KeyCodes'
import Status from './Status'
import { keyProfileEnum, TextCopied, userTypeEnum } from '../../constants'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'

interface IUnlockButton {
  status: Buffer | null
  setInputs: React.Dispatch<React.SetStateAction<IKeyCode | null>>
}

const handleUnlockButton = (setInputs: React.Dispatch<React.SetStateAction<IKeyCode | null>>) => {
  setInputs((state: IKeyCode | null) => (state === null ? null : { ...state, CompId1: null }))
}

const UnlockButton = (props: IUnlockButton) => {
  const { status, setInputs } = props
  if (status !== null) {
    return (
      <Button color='secondary' onClick={() => handleUnlockButton(setInputs)}>
        Unlock
      </Button>
    )
  }
}

interface IFormKeyCodes {
  handlerOnSubmit: React.FormEventHandler<HTMLFormElement>
  inputs: IKeyCode
  setInputs: React.Dispatch<React.SetStateAction<IKeyCode | null>>
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  handleClose: () => void
}

export default function FormKeyCodes({
  handlerOnSubmit,
  open,
  setOpen,
  inputs,
  setInputs,
  handleClose
}: IFormKeyCodes) {
  const isAdmin = useSelector((state: RootState) => state.keyCodesReducer.isAdmin)
  return (
    <form onSubmit={handlerOnSubmit}>
      <DialogContent
        sx={{ maxWidth: 1800, display: 'grid', gridTemplateColumns: { xs: '1fr', md: 'repeat(2,1fr)' }, gap: 4 }}
      >
        <Stack gap={1}>
          <FieldKeyValue keyValue='Key' value={inputs['KeyId']} />
          <FieldKeyValue keyValue='Company Name' value={inputs['CompanyName']} />
          <FieldKeyValue keyValue='Name' value={inputs['Name']} />
          <FieldKeyValue keyValue='Email' value={inputs['PrimaryEmail']} />
          <FieldKeyValue keyValue='Gemvision Account Number' value={inputs['GemvisionAccountNumber']} />
          <FieldKeyValue keyValue='Stuller Ship To Account No.' value={inputs['StullerShipToAccountNo']} />
          <FieldKeyValue keyValue='Address' value={inputs['StullerShipToAddress1']} />
          <FieldKeyValue keyValue='City' value={inputs['StullerShipToCity']} />
          <FieldKeyValue keyValue='State' value={inputs['StullerShipToState']} />
          <FieldKeyValue keyValue='Country Code' value={inputs['KeyCountryCode']} />
          <FieldKeyValue keyValue='Zip' value={inputs['StullerShipToZip']} />
          <FieldKeyValue keyValue='Last Startup' value={new Date(inputs['ApplicationStartupDate']).toLocaleString()} />
          <Typography>
            Status:{' '}
            <span style={{ opacity: 0.7 }}>
              <Status field='CompId1' value={inputs['CompId1']} fontSize='16px' />
            </span>
          </Typography>
        </Stack>
        <Stack>
          <Stack direction='row' alignItems='center'>
            <TextField
              color='secondary'
              margin='dense'
              id='Serial Code'
              label='Serial Code'
              variant='outlined'
              value={inputs['codes'] ?? ''}
              fullWidth
              disabled
            />
            {inputs['codes'] !== null && (
              <Tooltip
                title={TextCopied}
                open={open}
                onClose={() => setOpen(false)}
                leaveDelay={1000}
                placement='top'
                arrow
              >
                <IconButton
                  sx={{ ml: 1 }}
                  onClick={() => {
                    if (inputs['codes'] !== null) {
                      setOpen(true)
                      navigator.clipboard.writeText(inputs['codes'])
                    }
                  }}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
          <TextField
            label='Expire Date Library'
            value={inputs['ExpireDateLibrary'] ?? null}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setInputs((state: IKeyCode | null) =>
                state === null ? null : { ...state, ExpireDateLibrary: event.target.value }
              )
            }
            type='date'
            color='secondary'
            margin='dense'
            name='ExpireDateLibrary'
            id='Expire Date Library'
            variant='outlined'
            fullWidth
          />
          <FormControl fullWidth margin='dense'>
            <InputLabel id='demo-simple-select-label'>Key Profile Id</InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              name='KeyProfileId'
              value={inputs['KeyProfileId'] ?? ''}
              label='Key Profile Id'
              disabled={!isAdmin}
              onChange={(event: SelectChangeEvent<number>) =>
                setInputs((state: IKeyCode | null) =>
                  state === null ? null : { ...state, KeyProfileId: Number(event.target.value) }
                )
              }
            >
              {Object.entries(keyProfileEnum).map((item) => (
                <MenuItem key={item[0]} value={item[0]}>
                  {item[1]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin='dense'>
            <InputLabel id='demo-simple-select-label'>User Type Id</InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              name='UserTypeId'
              value={inputs['UserTypeId']}
              label='User Type Id'
              disabled={!isAdmin}
              onChange={(event: SelectChangeEvent<number>) =>
                setInputs((state: IKeyCode | null) =>
                  state === null ? null : { ...state, UserTypeId: Number(event.target.value) }
                )
              }
            >
              {Object.entries(userTypeEnum).map((item) => (
                <MenuItem key={item[0]} value={item[0]}>
                  {item[1]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControlLabel
            name='KeyEnabled'
            control={
              <Checkbox
                disabled={!isAdmin}
                checked={Boolean(inputs['KeyEnabled'])}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setInputs((state: IKeyCode | null) =>
                    state === null ? null : { ...state, KeyEnabled: Number(event.target.checked) }
                  )
                }
              />
            }
            label='Key Enabled'
          />
          <TextField
            color='secondary'
            type='number'
            margin='dense'
            id='ExportDesignBonus'
            name='ExportDesignBonus'
            label='Export Design Bonus'
            variant='outlined'
            value={inputs['ExportDesignBonus'] ?? ''}
            fullWidth
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setInputs((state: IKeyCode | null) =>
                state === null ? null : { ...state, ExportDesignBonus: Number(event.target.value) }
              )
            }
          />
          <FormControlLabel
            disabled
            control={<Checkbox disabled checked={Boolean(inputs['hasExportFeature'])} />}
            label='Has Export Feature'
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <UnlockButton setInputs={setInputs} status={inputs['CompId1']} />
        <Button color='secondary' onClick={handleClose}>
          Cancel
        </Button>
        <Button variant='contained' type='submit'>
          Save
        </Button>
      </DialogActions>
    </form>
  )
}
